import React from 'react'
import '../scss/MisCobros.scss'
import Footer from '../components/Footer'

const MisCobros = ({footerClass, footerHome, footerNovedades, footerTelefono}) => {
  return (
    <div id="app" className='app-mis-cobros'>
      <div id="misCobrosHeader">
        <img id='atras' src={require('../img/mis-documentos/atras.webp')} alt="" onClick={footerHome} />
        <p>Cobros</p>
      </div>

      <div id="infoContainer">
        <div className="info">
          <div className="main-title">
            <p className="title">Actualización de datos - PAS</p>
          </div>

          <p className="details">Si sos beneficiario del Programa de Acompañamiento social debes completar el formulario de actualización de datos.</p>
          <button>
            <p>Ingresar</p>
          </button>
        </div>
      </div>

      <div id="infoContainer">
        <div className="info">
          <div className="main-title">
            <p className="title">¿Necesitás ayuda?</p>
          </div>

          <p className="details">Podés realizar consultas o denunciar cualquier irregularidad en los programas del Ministerio de Capital Humano de manera segura y confidencial.</p>
          <button>
            <p>Contactanos</p>
          </button>
        </div>
      </div>

      <Footer 
        footerClass={footerClass}
        footerHome={footerHome}
        footerNovedades={footerNovedades}
        footerTelefono={footerTelefono} />
    </div>
  )
}

export default MisCobros