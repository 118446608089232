import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './App.scss';
import Preloader from './components/Preloader';

// Pre-pages
import LogIn from './pages/LogIn';
import PreviousLogIn from './pages/PreviousLogIn';
import CreateAccount from './pages/CreateAccount';

// Pages
import Home from './pages/Home';
import MisDocumentos from './pages/MisDocumentos';
import DniPin from './pages/DniPin';
import DniViewer from './pages/DniViewer';
import Options from './pages/Options';
import Novedades from './pages/Novedades';
import Telefonos from './pages/Telefonos';
import ViewDetails from './pages/ViewDetails';
import MisVehiculos from './pages/MisVehiculos';
import MiSalud from './pages/MiSalud';
import MiTrabajo from './pages/MiTrabajo';
import MisTurnos from './pages/MisTurnos';
import MisCobros from './pages/MisCobros';
import MisTramites from './pages/MisTramites';
import MisHijos from './pages/MisHijos';
import MiPerfil from './pages/MiPerfil';
import EditData from './pages/EditData';

const App = () => {
  const testVersion = false;
  const [currentPage, setCurrentPage] = useState(100);
  const [navbarColor, setNavbarColor] = useState('#ffffff');
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  const appStyle = {
    position: 'absolute',
    top: 0,
    width: '100vw'
  };

  // Color de la barra de estado
  useEffect(() => {
    if (currentPage === 100) {
      setNavbarColor('#ffffff');
    } else if (currentPage === 101) { 
      setNavbarColor('#ffffff')
    } else if (currentPage === 102) {
      setNavbarColor('#ffffff');
    } else if (currentPage === 1) {
      setNavbarColor('#362fc1');
    } else if (currentPage === 2) {
      setNavbarColor('#362fc1');
    } else if (currentPage === 3) {
      setNavbarColor('#362fc1')
    } else if (currentPage === 4) {
      setNavbarColor('#362fc1')
    } else if (currentPage === 5) {
      setNavbarColor('#362fc1')
    } else if (currentPage === 6) {
      setNavbarColor('#362fc1')
    } else if (currentPage === 7) {
      setNavbarColor('#362fc1')
    } else if (currentPage === 8) {
      setNavbarColor('362fc1')
    } else if (currentPage === 9) { 
      setNavbarColor('#362fc1')
    } else if (currentPage === 10) {
      setNavbarColor('#362fc1') 
    } else if (currentPage === 11) {
      setNavbarColor('#362fc1') 
    } else if (currentPage === 12) { 
      setNavbarColor('#362fc1') 
    } else if (currentPage === 13) { 
      setNavbarColor('#362fc1') 
    } else if (currentPage === 14) { 
      setNavbarColor('#362fc1') 
    } else if (currentPage === 15) { 
      setNavbarColor('#362fc1') 
    } else if (currentPage === 16) {
      setNavbarColor('#362fc1')
    } else if (currentPage === 17) {
      setNavbarColor('#ffffff')
    }

  }, [currentPage]);

  // Definir páginas
  const [page100, setPage100] = useState('');
  const [page100Time, setPage100Time] = useState(0);
  const [page101, setPage101] = useState('');
  const [page101Time, setPage101Time] = useState(0);
  const [page102, setPage102] = useState('');
  const [page102Time, setPage102Time] = useState(0);

  const [page1, setPage1] = useState('');
  const [page1Time, setPage1Time] = useState(0);
  const [page2, setPage2] = useState('');
  const [page2Time, setPage2Time] = useState(0);
  const [page3, setPage3] = useState('');
  const [page3Time, setPage3Time] = useState(0);
  const [page4, setPage4] = useState('');
  const [page4Time, setPage4Time] = useState(0);
  const [page5, setPage5] = useState('');
  const [page5Time, setPage5Time] = useState(0);
  const [page6, setPage6] = useState('');
  const [page6Time, setPage6Time] = useState(0);
  const [page7, setPage7] = useState('');
  const [page7Time, setPage7Time] = useState(0);
  const [page8, setPage8] = useState('');
  const [page8Time, setPage8Time] = useState(0);
  const [page9, setPage9] = useState('');
  const [page9Time, setPage9Time] = useState(0);
  const [page10, setPage10] = useState('');
  const [page10Time, setPage10Time] = useState(0);
  const [page11, setPage11] = useState('');
  const [page11Time, setPage11Time] = useState(0);
  const [page12, setPage12] = useState('');
  const [page12Time, setPage12Time] = useState(0);
  const [page13, setPage13] = useState('');
  const [page13Time, setPage13Time] = useState(0);
  const [page14, setPage14] = useState('');
  const [page14Time, setPage14Time] = useState(0);
  const [page15, setPage15] = useState('');
  const [page15Time, setPage15Time] = useState(0);
  const [page16, setPage16] = useState('');
  const [page16Time, setPage16Time] = useState(0);
  const [page17, setPage17] = useState('');
  const [page17Time, setPage17Time] = useState(0);
  const [footerClass, setFooterClass] = useState('');

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  // Funciones de transición de páginas
  const goToPageRight = (currentPage, currentPageTime, destinyPage, destinyPageTime, page, footerStay) => {
    currentPage('page-left')
    currentPageTime(400)
    destinyPage('page-right')
    destinyPageTime(400)
    setCurrentPage(page);
    footerStay ? setFooterClass('footerRight') : setFooterClass('');
    scrollToTop()
  };

  const goToPageLeft = (currentPage, currentPageTime, destinyPage, destinyPageTime, page, footerStay) => {
    currentPage('page-right')
    currentPageTime(400)
    destinyPage('page-left')
    destinyPageTime(400)
    setCurrentPage(page);
    footerStay ? setFooterClass('footerLeft') : setFooterClass('');
    scrollToTop()
  };

  const goToPageClear = (currentPage, currentPageTime, destinyPage, destinyPageTime, page) => {
    currentPage('')
    currentPageTime(0)
    destinyPage('')
    destinyPageTime(0)
    setCurrentPage(page);
    setFooterClass('')
  }

  // Detecta usuario actual y carga datos 
  
  
  const handleUserLogin = (user) => {
    setUser(user);
    setLoggedIn(true);
    setCurrentPage(1); // startPage
    localStorage.removeItem('newUser');
    
    localStorage.setItem('logInUsername', user.user);
    localStorage.setItem('logInPassword', user.password);
    localStorage.setItem('name', user.data.name);
    localStorage.setItem('lastname', user.data.lastname);
    localStorage.setItem('sex', user.data.sex);
    localStorage.setItem('dateOfBirth', JSON.stringify(user.data.dateOfBirth));
    localStorage.setItem('home', user.data.home);
    localStorage.setItem('id', user.id);
    localStorage.setItem('dni', user.data.dni);
    localStorage.setItem('username', user.user);
    localStorage.setItem('password', user.password);
    localStorage.setItem('profilePhoto', user.profilePhoto);
    localStorage.setItem('signature', user.signature || '')

    localStorage.setItem('securityNumber1', user.otherData.securityNumber1);
    localStorage.setItem('securityNumber2', user.otherData.securityNumber2);
    localStorage.setItem('securityNumber3', user.otherData.securityNumber3);
    localStorage.setItem('securityNumber4', user.otherData.securityNumber4);
    localStorage.setItem('cuilLastNumber', user.otherData.cuilLastNumber);

    localStorage.setItem('nationality', user.extraData.nationality);
    localStorage.setItem('examplary', user.extraData.examplary);
    localStorage.setItem('tramitNumber', user.extraData.tramitNumber);
    localStorage.setItem('emissionDate', JSON.stringify(user.extraData.emissionDate));
    localStorage.setItem('expirationDate', JSON.stringify(user.extraData.expirationDate));
  };

  // Funcion para detectar en que footerPage estás
  const [FN1, setFN1] = useState(() => setPage1);
  const [FN2, setFN2] = useState(() => setPage1Time);
  const [FN3, setFN3] = useState(1);

  const FNNovedades = () => {
    setFN1(() => setPage6);
    setFN2(() => setPage6Time);
    setFN3(6);
  }

  const FNInicio = () => {
    setFN1(() => setPage1);
    setFN2(() => setPage1Time);
    setFN3(1);
  }

  const FNTelefono = () => {
    setFN1(() => setPage7);
    setFN2(() => setPage7Time);
    setFN3(7);
  }

  return (
    <div id="pages">
      <div id='testVersion' style={{display: testVersion ? "flex" : "none"}}>
        <p>TEST</p>
        <p>TEST</p>
        <p>TEST</p>
        <p>TEST</p>
      </div>
      <meta name="theme-color" content={navbarColor} />
      <Preloader />

      {loggedIn ? (
        <>
          {/* Páginas cuando está iniciado sesión */}

          <CSSTransition
            in={currentPage === 5}
            timeout={page5Time}
            unmountOnExit
            classNames={page5}
          >
            <div className="page" style={appStyle}>
              <Options 
              user={user}
              goToPage={() => goToPageRight(setPage5, setPage5Time, FN1, FN2, FN3, true)}
              goToPage2={() => {goToPageRight(setPage5, setPage5Time, setPage16, setPage16Time, 16, true)}}
              goToPage3={() => goToPageRight(setPage5, setPage5Time, setPage17, setPage17Time, 17)}
              footerClass={footerClass}
              footerHome={() => {goToPageRight(setPage5, setPage5Time, setPage1, setPage1Time, 1, true); FNInicio()}}
              footerNovedades={() => {goToPageClear(setPage4, setPage5Time, setPage6, setPage6Time, 6); FNNovedades()}}
              footerTelefono={() => {goToPageClear(setPage5, setPage5Time, setPage7, setPage7Time, 7); FNTelefono()}} />
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 1}
            timeout={page1Time}
            unmountOnExit
            classNames={page1}
          >
            <div className="page" style={appStyle}>
              <Home
                userName={user.data.name}
                goToPage={() => goToPageRight(setPage1, setPage1Time, setPage2, setPage2Time, 2, true)}
                goToPage2={() => goToPageLeft(setPage1, setPage1Time, setPage5, setPage5Time, 5, true)}
                goToPage3={() => {goToPageRight(setPage1, setPage1Time, setPage9, setPage9Time, 9, true)}}
                goToPage4={() => {goToPageRight(setPage1, setPage1Time, setPage10, setPage10Time, 10, true)}}
                goToPage5={() => {goToPageRight(setPage1, setPage1Time, setPage11, setPage11Time, 11, true)}}
                goToPage6={() => {goToPageRight(setPage1, setPage1Time, setPage12, setPage12Time, 12, true)}}
                goToPage7={() => {goToPageRight(setPage1, setPage1Time, setPage13, setPage13Time, 13, true)}}
                goToPage8={() => {goToPageRight(setPage1, setPage1Time, setPage14, setPage14Time, 14, true)}}
                goToPage9={() => {goToPageRight(setPage1, setPage1Time, setPage15, setPage15Time, 15, true)}}
                footerClass={footerClass}
                footerNovedades={() => {goToPageClear(setPage1, setPage1Time, setPage6, setPage6Time, 6); FNNovedades()}}
                footerTelefono={() => {goToPageClear(setPage1, setPage1Time, setPage7, setPage7Time, 7); FNTelefono()}} />
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 2}
            timeout={page2Time}
            unmountOnExit
            classNames={page2}
          >
            <div className="page" style={appStyle}>
              <MisDocumentos
                goToPage={() => goToPageLeft(setPage2, setPage2Time, setPage1, setPage1Time, 1, true)}
                goToPage2={() => goToPageClear(setPage2, setPage2Time, setPage3, setPage3Time, 3)}
                footerClass={footerClass}
                footerHome={() => {goToPageLeft(setPage2, setPage2Time, setPage1, setPage1Time, 1, true); FNInicio()}}
                footerNovedades={() => {goToPageClear(setPage2, setPage2Time, setPage6, setPage6Time, 6); FNNovedades()}}
                footerTelefono={() => {goToPageClear(setPage2, setPage2Time, setPage7, setPage7Time, 7); FNTelefono()}} />

            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 3}
            timeout={page3Time}
            unmountOnExit
            classNames={page3}
          >
            <div className="page" style={appStyle}>
              <DniPin
                goToPage={() => goToPageClear(setPage3, setPage3Time, setPage2, setPage2Time, 2)}
                goToPage2={() => goToPageRight(setPage3, setPage3Time, setPage4, setPage4Time, 4, true)}
                footerClass={footerClass}
                footerHome={() => {goToPageLeft(setPage3, setPage3Time, setPage1, setPage1Time, 1, true); FNInicio()}}
                footerNovedades={() => {goToPageClear(setPage3, setPage3Time, setPage6, setPage6Time, 6); FNNovedades()}} 
                footerTelefono={() => {goToPageClear(setPage3, setPage3Time, setPage7, setPage7Time, 7); FNTelefono()}} />
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 4}
            timeout={page4Time}
            unmountOnExit
            classNames={page4}
          >
            <div className="page" style={appStyle}>
              <DniViewer
                userName={user.data.name}
                userLastname={user.data.lastname}
                goToPage={() => goToPageLeft(setPage4, setPage4Time, setPage3, setPage3Time, 3, true)}
                footerClass={footerClass}
                footerHome={() => {goToPageLeft(setPage4, setPage4Time, setPage1, setPage1Time, 1, true); FNInicio()}}
                footerNovedades={() => {goToPageClear(setPage4, setPage4Time, setPage6, setPage6Time, 6); FNNovedades()}} 
                footerTelefono={() => {goToPageClear(setPage4, setPage4Time, setPage7, setPage7Time, 7); FNTelefono()}}
                goToPage2={() => goToPageRight(setPage4, setPage4Time, setPage8, setPage8Time, 8, true)} />
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 6}
            timeout={page6Time}
            unmountOnExit
            classNames={page6}
          >
            <div className="page" style={appStyle}>
              <Novedades 
                footerClass={footerClass}
                footerHome={() => {goToPageClear(setPage6, setPage6Time, setPage1, setPage1Time, 1); FNInicio()}}
                goToPage={() => goToPageLeft(setPage6, setPage6Time, setPage5, setPage5Time, 5, true)}
                footerTelefono={() => {goToPageClear(setPage6, setPage6Time, setPage7, setPage7Time, 7); FNTelefono()}} />
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 7}
            timeout={page7Time}
            unmountOnExit
            classNames={page7}
          >
            <div className="page" style={appStyle}>
              <Telefonos 
                goToPage={() => goToPageLeft(setPage7, setPage7Time, setPage5, setPage5Time, 5, true)}
                footerHome={() => {goToPageClear(setPage7, setPage7Time, setPage1, setPage1Time, 1); FNInicio()}}
                footerNovedades={() => {goToPageClear(setPage7, setPage7Time, setPage6, setPage6Time, 6); FNNovedades()}}
                footerClass={footerClass} />
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 8}
            timeout={page8Time}
            unmountOnExit
            classNames={page8}
          >
            <div className="page" style={appStyle}>
              <ViewDetails
                footerClass={footerClass} 
                goToPage={() => goToPageLeft(setPage8, setPage8Time, setPage4, setPage4Time, 4, true)}
                footerHome={() => {goToPageClear(setPage8, setPage8Time, setPage1, setPage1Time, 1); FNInicio()}}
                footerNovedades={() => {goToPageClear(setPage8, setPage8Time, setPage6, setPage6Time, 6); FNNovedades()}}
                footerTelefono={() => {goToPageClear(setPage8, setPage8Time, setPage7, setPage7Time, 7); FNTelefono()}}
                 />
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 9}
            timeout={page9Time}
            unmountOnExit
            classNames={page9}
          >
            <div className="page" style={appStyle}>
              <MisVehiculos
                footerClass={footerClass}
                footerHome={() => {goToPageLeft(setPage9, setPage9Time, setPage1, setPage1Time, 1, true); FNInicio()}}
                footerNovedades={() => {goToPageClear(setPage9, setPage9Time, setPage6, setPage6Time, 6); FNNovedades()}}
                footerTelefono={() => {goToPageClear(setPage9, setPage9Time, setPage7, setPage7Time, 7); FNTelefono()}} />
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 10}
            timeout={page10Time}
            unmountOnExit
            classNames={page10}
          >
            <div className="page" style={appStyle}>
              <MiSalud
                footerClass={footerClass}
                footerHome={() => {goToPageLeft(setPage10, setPage10Time, setPage1, setPage1Time, 1, true); FNInicio()}}
                footerNovedades={() => {goToPageClear(setPage10, setPage10Time, setPage6, setPage6Time, 6); FNNovedades()}}
                footerTelefono={() => {goToPageClear(setPage10, setPage10Time, setPage7, setPage7Time, 7); FNTelefono()}} />
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 11}
            timeout={page11Time}
            unmountOnExit
            classNames={page11}
          >
            <div className="page" style={appStyle}>
              <MiTrabajo
                footerClass={footerClass}
                footerHome={() => {goToPageLeft(setPage11, setPage11Time, setPage1, setPage1Time, 1, true); FNInicio()}}
                footerNovedades={() => {goToPageClear(setPage11, setPage11Time, setPage6, setPage6Time, 6); FNNovedades()}}
                footerTelefono={() => {goToPageClear(setPage11, setPage11Time, setPage7, setPage7Time, 7); FNTelefono()}}
                user={user} /> 
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 12}
            timeout={page12Time}
            unmountOnExit
            classNames={page12}
          >
            <div className="page" style={appStyle}>
              <MisTurnos
                footerClass={footerClass}
                footerHome={() => {goToPageLeft(setPage12, setPage12Time, setPage1, setPage1Time, 1, true); FNInicio()}}
                footerNovedades={() => {goToPageClear(setPage12, setPage12Time, setPage6, setPage6Time, 6); FNNovedades()}}
                footerTelefono={() => {goToPageClear(setPage12, setPage12Time, setPage7, setPage7Time, 7); FNTelefono()}} /> 
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 13}
            timeout={page13Time}
            unmountOnExit
            classNames={page13}
          >
            <div className="page" style={appStyle}>
              <MisCobros
                footerClass={footerClass}
                footerHome={() => {goToPageLeft(setPage13, setPage13Time, setPage1, setPage1Time, 1, true); FNInicio()}}
                footerNovedades={() => {goToPageClear(setPage13, setPage13Time, setPage6, setPage6Time, 6); FNNovedades()}}
                footerTelefono={() => {goToPageClear(setPage13, setPage13Time, setPage7, setPage7Time, 7); FNTelefono()}} /> 
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 14}
            timeout={page14Time}
            unmountOnExit
            classNames={page14}
          >
            <div className="page" style={appStyle}>
              <MisTramites
                footerClass={footerClass}
                footerHome={() => {goToPageLeft(setPage14, setPage14Time, setPage1, setPage1Time, 1, true); FNInicio()}}
                footerNovedades={() => {goToPageClear(setPage14, setPage14Time, setPage6, setPage6Time, 6); FNNovedades()}}
                footerTelefono={() => {goToPageClear(setPage14, setPage14Time, setPage7, setPage7Time, 7); FNTelefono()}} /> 
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 15}
            timeout={page15Time}
            unmountOnExit
            classNames={page15}
          >
            <div className="page" style={appStyle}>
              <MisHijos
                footerClass={footerClass}
                footerHome={() => {goToPageLeft(setPage15, setPage15Time, setPage1, setPage1Time, 1, true); FNInicio()}}
                footerNovedades={() => {goToPageClear(setPage15, setPage15Time, setPage6, setPage6Time, 6); FNNovedades()}}
                footerTelefono={() => {goToPageClear(setPage15, setPage15Time, setPage7, setPage7Time, 7); FNTelefono()}} /> 
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 16}
            timeout={page16Time}
            unmountOnExit
            classNames={page16}
          >
            <div className="page" style={appStyle}>
              <MiPerfil 
                user={user}
                goToPage={() => goToPageLeft(setPage16, setPage16Time, setPage5, setPage5Time, 5, true)}
                footerClass={footerClass}
                footerHome={() => {goToPageRight(setPage16, setPage16Time, setPage1, setPage1Time, 1, true); FNInicio()}}
                footerNovedades={() => {goToPageClear(setPage16, setPage16Time, setPage6, setPage6Time, 6); FNNovedades()}}
                footerTelefono={() => {goToPageClear(setPage16, setPage16Time, setPage7, setPage7Time, 7); FNTelefono()}} />
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 17}
            timeout={page17Time}
            unmountOnExit
            classNames={page17}
          >
            <div className="page" style={appStyle}>
              <EditData 
                user={user}
                goToPage={() => goToPageLeft(setPage17, setPage17Time, setPage5, setPage5Time, 5, true)} />
            </div>
          </CSSTransition>
        </>


      ) : (
        <div id="pages">
          {/* Páginas antes de iniciar sesión */}

          <meta name='theme-color' content={navbarColor} />
          <CSSTransition
            in={currentPage === 100}
            timeout={page100Time}
            unmountOnExit
            classNames={page100}
          >
            <div className="page" style={appStyle} >
              <PreviousLogIn
                goToPage={() => {goToPageRight(setPage100, setPage100Time, setPage101, setPage101Time, 101)}}
                goToPage2={() => {goToPageRight(setPage100, setPage100Time, setPage102, setPage102Time, 102)}}
                onUserLogin={handleUserLogin} />
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 101}
            timeout={page101Time}
            unmountOnExit
            classNames={page101}
          >
            <div className="page" style={appStyle} >
              <LogIn 
                goToPage={() => {goToPageLeft(setPage101, setPage101Time, setPage100, setPage100Time, 100)}}
                goToPage2={() => {goToPageRight(setPage101, setPage101Time, setPage102, setPage102Time, 102)}}
                onUserLogin={handleUserLogin} />
            </div>
          </CSSTransition>
          <CSSTransition
            in={currentPage === 102}
            timeout={page102Time}
            unmountOnExit
            classNames={page102}
          >
            <div className="page" style={appStyle} >
              <CreateAccount 
                goToPage={() => goToPageLeft(setPage102, setPage102Time, setPage100, setPage100Time, 100)} />
            </div>
          </CSSTransition>
        </div>
      )}
    </div>
  );
};

export default App;
