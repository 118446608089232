import React from 'react'
import '../scss/MiTrabajo.scss'
import Footer from '../components/Footer'

const MiTrabajo = ({footerClass, footerHome, footerNovedades, footerTelefono, user}) => {
  return (
    <div id="app" className='app-mi-trabajo'>
      <div id="miTrabajoHeader">
        <img src={require('../img/mis-documentos/atras.webp')} alt="" id="atras" onClick={footerHome} />
        <p>Trabajo</p>
      </div>

      <div id="constanciaDeCuilContainer">
        <div id="constanciaDeCuil">
          <div id="datos">
            <div className="dato">
              <p id="title">Nombre</p>
              <p id="description">{user ? user.data.name : null}</p>
            </div>
            <div className="dato">
              <p id="title">Apellido</p>
              <p id="description">{user ? user.data.lastname: null}</p>
            </div>
            <div className="dato">
              <p id="title">CUIL</p>
              <p id="description">{user ? (user.data.sex === 'M' ? 20 : 27) : null}-{user ? user.data.dni : null}-{user ? user.otherData.cuilLastNumber : null}</p>
            </div>
          </div>

          <button>
            <p>Descargar constancia de CUIL</p>
          </button>
        </div>

        <div id="divider-line"></div>

        <p id='suministratedBy'>Información suministrada por</p>
        <p id='anses'>ANSES</p>
      </div>

      <Footer 
        footerClass={footerClass}
        footerNovedades={footerNovedades}
        footerHome={footerHome}
        footerTelefono={footerTelefono}
      />
    </div>
  )
}

export default MiTrabajo