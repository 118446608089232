import React from 'react';
import '../scss/Footer.scss';
import { Link } from 'react-router-dom';

const Footer = ({ inicio, novedades, telefonos, footerClass, footerHome, footerNovedades, footerTelefono }) => {
  const inicioValue = inicio !== undefined ? 'inicio-selected' : 'inicio';
  const novedadesValue = novedades !== undefined ? 'novedades-selected' : 'novedades';
  const telefonosValue = telefonos !== undefined ? 'telefonos-selected' : 'telefonos';

  return (
    <div id="footer" className={footerClass}>
      <div className="footer-button" onClick={() => footerHome()}>
        <img src={require(`../img/footer/${inicioValue}.webp`)} alt="" />
        <p id={inicioValue}>Inicio</p>
      </div>
      <div className="footer-button" onClick={() => footerNovedades()}>
        <img src={require(`../img/footer/${novedadesValue}.webp`)} alt="" />
        <p id={novedadesValue}>Novedades</p>
      </div>
      <div className="footer-button" onClick={() => footerTelefono()}>
        <img src={require(`../img/footer/${telefonosValue}.webp`)} alt="" />
        <p id={telefonosValue}>Teléfonos</p>
      </div>
      <div className="footer-button">
        <Link to={'https://wa.me/5491139101010?text=%C2%A1Hola!%20Quiero%20hacerte%20una%20consulta.'}>
          <img src={require(`../img/footer/tina.webp`)} alt="" />
          <p>Tina</p>
        </Link>
      </div>
    </div>
  )
};

export default Footer;
