import React from 'react'
import '../scss/Options.scss';
import Footer from '../components/Footer';

const Options = ({ user, footerClass, goToPage, footerHome, footerNovedades, footerTelefono, goToPage2, goToPage3 }) => {

  const logOut = () => {
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('name');
    localStorage.removeItem('lastname');
    localStorage.removeItem('sex');
    localStorage.removeItem('home');
    localStorage.removeItem('id');
    localStorage.removeItem('dni');
    localStorage.removeItem('dateOfBirth');
    localStorage.removeItem('nationality');
    localStorage.removeItem('examplary');
    localStorage.removeItem('tramitNumber');
    localStorage.removeItem('emissionDate');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('securityNumber1');
    localStorage.removeItem('securityNumber2');
    localStorage.removeItem('securityNumber3');
    localStorage.removeItem('securityNumber4');
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    localStorage.removeItem('profilePhoto');
    localStorage.removeItem('lastDatabaseDate');
    localStorage.removeItem('cuilLastNumber');
    localStorage.removeItem('signature');

    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  return (
    <div id="app" className='app-options' style={{backgroundColor: 'white'}}>
        <div id="options-header">
            <img src={require('../img/options/atras.webp')} alt="" id='atras' onClick={() => goToPage()}/>
            <img src={require('../img/header-logo.webp')} alt="" id='logo' />
        </div>

        <div id="options-header-continue">
            <img src={require('../img/options/verified-user.webp')} alt="" id='verified-user-img' />
            <div id="verified-data">
                <p id='name'>{user.data.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')} {user.data.lastname.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}</p>
                <p id='cuil'>CUIL: {user.data.sex === 'M' ? '20' : '27'}{user.data.dni}{user.otherData.cuilLastNumber}</p>
                <p id='verified'>Identidad validada</p>
            </div>
        </div>

        <div id="options-selecter">
          <div className="option" onClick={() => {/* goToPage2() */}}>
            <img src={require('../img/options/mi-perfil.webp')} alt="" />
            <p>Mi Perfil</p>
          </div>
          <div className="option">
            <img src={require('../img/options/hijos-asociados.webp')} alt="" />
            <p>Hijos/as asociados</p>
          </div>
          <div className="option">
            <img src={require('../img/options/suscribir-servicios.webp')} alt="" />
            <p>Suscribir servicios</p>
          </div>

          <div id="divider-line"></div>

          <div className="option">
            <img src={require('../img/options/seguridad-y-privacidad.webp')} alt="" />
            <p>Seguridad y privacidad</p>
          </div>

          <div id="divider-line"></div>

          <div className="option" onClick={() => goToPage3()}>
            <img src={require('../img/options/acerca-de.webp')} alt="" />
            <p>Editar datos</p>
          </div>
          <div className="option">
            <img src={require('../img/options/terminos-y-condiciones.webp')} alt="" />
            <p>Términos y condiciones</p>
          </div>

          <div id="divider-line"></div>

          <div className="option" onClick={() => logOut()}>
            <img src={require('../img/options/cerrar-sesion.webp')} alt="" />
            <p>Cerrar sesión</p>
          </div>
          <div className="option" onClick={() => footerHome()}>
            <img src={require('../img/options/volver-al-inicio.webp')} alt="" />
            <p>Volver al inicio</p>
          </div>
        </div>

        <Footer 
          footerClass={footerClass} 
          footerHome={() => footerHome()}
          footerNovedades={footerNovedades}
          footerTelefono={footerTelefono} />
    </div>
  )
}

export default Options