import React, { useEffect, useState, useCallback } from 'react';
import '../scss/DniComponent.scss';
import PDF417Barcode from './PDF417Barcode';
import Base64Image from './Base64Image';

const DniComponent = () => {
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [sex, setSex] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [home, setHome] = useState('');
  const [dni, setDni] = useState('');
  const [securityNumber1, setSecurityNumber1] = useState('8');
  const [securityNumber2, setSecurityNumber2] = useState('8');
  const [securityNumber3, setSecurityNumber3] = useState('3808574');
  const [securityNumber4, setSecurityNumber4] = useState('4');
  const [base64String, setBase64String] = useState('');
  const [signature, setSignature] = useState('');

  // Datos opcionales
  const [nationality, setNationality] = useState('Argentina');
  const [examplary, setExamplary] = useState('A');
  const [emissionDate, setEmissionDate] = useState('0064929313892648');
  const [expirationDate, setExpirationDate] = useState({ day: '13', month: '02', year: '21' });
  const [tramitNumber, setTramitNumber] = useState({ day: "13", month: "02", year: "36" });
  const [tramitNumber11, setTramitNumber11] = useState();

  const initializeDataFromLocalStorage = useCallback(() => {
    setName(localStorage.getItem('name') || '');
    setLastname(localStorage.getItem('lastname') || '');
    setSex(localStorage.getItem('sex') || '');
    setHome(localStorage.getItem('home') || '');
    setDni(localStorage.getItem('dni') || '');
    const dateOfBirthString = localStorage.getItem('dateOfBirth') || '';
    if (dateOfBirthString) {
      setDateOfBirth(JSON.parse(dateOfBirthString));
    }
    setSecurityNumber1(localStorage.getItem('securityNumber1') || '8');
    setSecurityNumber2(localStorage.getItem('securityNumber2') || '8');
    setSecurityNumber3(localStorage.getItem('securityNumber3') || '3808574');
    setSecurityNumber4(localStorage.getItem('securityNumber4') || '4');
    setBase64String(localStorage.getItem('profilePhoto') || '');
    setSignature(localStorage.getItem('signature') || '')

    // Datos opcionales
    setNationality(localStorage.getItem('nationality') || 'Argentina');
    setExamplary(localStorage.getItem('examplary') || 'A');
    setTramitNumber(localStorage.getItem('tramitNumber') || '0064929313892648');
    const emissionDateString = localStorage.getItem('emissionDate') || '{"day":"13","month":"02","year":"21"}';
    const expirationDateString = localStorage.getItem('expirationDate') || '{"day":"13","month":"02","year":"36"}';
    setEmissionDate(JSON.parse(emissionDateString));
    setExpirationDate(JSON.parse(expirationDateString));
    setTramitNumber11(String(tramitNumber).slice(0, 11));
  }, [setName, setLastname, setSex, setHome, setDni, setDateOfBirth, setNationality, setExamplary, setTramitNumber, setEmissionDate, setExpirationDate, setTramitNumber11, tramitNumber]);

  useEffect(() => {
    initializeDataFromLocalStorage();
  }, [initializeDataFromLocalStorage]);

  const separarLetras = (frase) => {
    const letrasArray = [];
    for (let i = 0; i < frase.length; i++) {
      letrasArray.push(frase.toLowerCase()[i]);
    }
    return letrasArray;
  };

  const wrapLetras = (string) => {
    const palabras = string.split(' ');
    const divs = palabras.map((palabra, index) => (
      <div key={index}>
        {devolverLetrasImagen(palabra)}
      </div>
    ));
    return divs;
  };

  const devolverLetrasImagen = (string) => {
    const frase = separarLetras(string);

    return frase.map((caracter, index) => {
      if (caracter === ' ') {
        return <div key={index} id="blank-space" />;
      } else if (caracter === '/') {
        return <img key={index} className='slash' src={require(`../img/letras-dni/slash.webp`)} alt={`caracter ${caracter}`} />;
      } else if (/[a-zA-Z0-9]/.test(caracter)) {
        return <img key={index} src={require(`../img/letras-dni/${caracter.toUpperCase()}.webp`)} alt={`caracter ${caracter}`} />;
      } else {
        return null;
      }
    });
  };

  const devolverNumerosImagen = (cadenaNumerica) => {
    const numeros = separarLetras(cadenaNumerica);

    return numeros.map((numero, indice) => {
      if (numero === ' ') {
        return <div key={indice} id="blank-space" />;
      } else if (/[0-9]/.test(numero)) {
        return <img key={indice} className="numberM" src={require(`../img/letras-dni/${numero}M.webp`)} alt={`numero ${numero}`} />;
      } else if (numero === '.') {
        return <img key={indice} className='point' src={require(`../img/letras-dni/puntoM.webp`)} alt={`numero ${numero}`} />;
      } else {
        return null;
      }
    });
  };

  const [activarClick, setActivarClick] = useState(true);
  const [frontClasses, setFrontClasses] = useState('front');
  const [backClasses, setBackClasses] = useState('back');
  const [frontStyle, setFrontStyle] = useState({ display: 'flex' });
  const [backStyle, setBackStyle] = useState({ display: 'none' });
  const [dotFront, setDotFront] = useState('dotActive');
  const [dotBack, setDotBack] = useState('dotDisabled');

  function handleCardClick() {
    if (activarClick) {
      setActivarClick(false);

      if (frontClasses === 'front') {
        setFrontClasses('back');
        setBackClasses('front');

        setFrontStyle({ animation: 'rotateFront 200ms ease-in forwards' })

        setTimeout(() => {
          setFrontStyle({ display: 'none' });
          setBackStyle({ display: 'flex', animation: 'rotateBack 200ms ease-out forwards' });
          setDotFront('dotDisabled');
          setDotBack('dotActive');

          setTimeout(() => {
            setActivarClick(true)
          }, 150)
        }, 200);
      } else {
        setFrontClasses('front');
        setBackClasses('back');

        setBackStyle({ animation: 'rotateBack2 200ms ease-in forwards' })

        setTimeout(() => {
          setBackStyle({ display: 'none' });
          setFrontStyle({ display: 'flex', animation: 'rotateFront2 200ms ease-out forwards' });
          setDotFront('dotActive');
          setDotBack('dotDisabled');

          setTimeout(() => {
            setActivarClick(true)
          }, 150)
        }, 200);
      }
    }
  }

  const formatNumber = (numberString) => {
    const cleanedNumber = numberString.replace(/\D/g, '');

    const parts = [];
    let i = cleanedNumber.length;
    while (i > 0) {
      parts.push(cleanedNumber.substring(Math.max(0, i - 3), i));
      i -= 3;
    }

    return parts.reverse().join('.');
  };

  const returnDate = (day, month, year) => {
    const monthNames = {
      '01': 'ene/jan',
      '02': 'feb/feb',
      '03': 'mar/mar',
      '04': 'abr/apr',
      '05': 'may/may',
      '06': 'jun/jun',
      '07': 'jul/jul',
      '08': 'ago/aug',
      '09': 'sep/sep',
      '10': 'oct/oct',
      '11': 'nov/nov',
      '12': 'dic/dec',
    };
    const monthString = monthNames[month] || '';
    return `${day} ${monthString} ${Number(year) <= 40 ? '20' : '19'}${year}`;
  }

  const mechanicalReading = (string) => {
    const letras = separarLetras(string);

    return letras.map((letra, indice) => {
      if (letra === ' ') {
        return <span key={indice}>{'<'}</span>
      } else {
        return <span key={indice}>{letra.toUpperCase()}</span>;
      }
    })
  }

  return (
    <div id="dniComponentContainer">
      <div id="dniComponent">
        <div id="frontDniContainer" className={frontClasses} style={frontStyle} onClick={handleCardClick} onMouseDown={handleCardClick} onTouchStart={handleCardClick}>
          <img id="frontDni" src={require('../img/dni/front.webp')} alt="front DNI" />
          <div id="text">
            {base64String !== '' ?
              (<div id="profilePhoto">
                <Base64Image base64={base64String} />
              </div>)
              : null}
            {signature !== '' ?
              (<div id="signature">
                <Base64Image base64={signature} />
              </div>)
              : null}
            <div id="surname" className='imgLetters'>
              {devolverLetrasImagen(lastname)}
            </div>
            <div id="name" className='imgLetters'>
              {devolverLetrasImagen(name)}
            </div>
            <div id='dni'>
              {devolverNumerosImagen(`${formatNumber(dni)}`)}
            </div>
            <div id="sex" className="imgLetters">
              {devolverLetrasImagen(sex)}
            </div>
            <div id="dateOfBirth" className="imgLetters">
              {devolverLetrasImagen(returnDate(dateOfBirth.day, dateOfBirth.month, dateOfBirth.year))}
            </div>
            <div id="emissionDate" className="imgLetters">
              {devolverLetrasImagen(returnDate(emissionDate.day, emissionDate.month, emissionDate.year))}
            </div>
            <div id="expirationDate" className="imgLetters">
              {devolverLetrasImagen(returnDate(expirationDate.day, expirationDate.month, expirationDate.year))}
            </div>
            <div id="tramitNumber" className="imgLetters">
              {devolverLetrasImagen(tramitNumber)}
            </div>
            <div id="nationality" className="imgLetters">
              {devolverLetrasImagen(nationality)}
            </div>
            <div id="examplary" className="imgLetters">
              {devolverLetrasImagen(examplary)}
            </div>
            <div id="barCode">
              <PDF417Barcode value={`${tramitNumber11}@${lastname.toUpperCase()}@${name.toUpperCase()}@${sex}@${dni}@${examplary}@${dateOfBirth.day}/${dateOfBirth.month}/20${dateOfBirth.year}@${emissionDate.day}/${emissionDate.month}/20${emissionDate.year}`} />
            </div>
          </div>
        </div>

        <div id="backDniContainer" className={backClasses} style={backStyle} onClick={handleCardClick} onMouseDown={handleCardClick} onTouchStart={handleCardClick}>
          <img src={require('../img/dni/back.webp')} alt="" id="backDni" />
          <div id="home" >
            {wrapLetras(home)}
          </div>
          <div id="mechanicalReading1" className='mechanicalReading'>
            <p>{mechanicalReading(`IDARG${dni}<${securityNumber1}<<<<<<<<<<<<<<<`)}</p>
          </div>
          <div id="mechanicalReading2" className='mechanicalReading'>
            <p>{mechanicalReading(`${dateOfBirth.year}${dateOfBirth.month}${dateOfBirth.day}${securityNumber2}${sex}${securityNumber3}${String(nationality).slice(0, 3).toUpperCase()}<<<<<<<<<<<${securityNumber4}`)}</p>
          </div>
          <div id="mechanicalReading3" className="mechanicalReading">
            <p>{mechanicalReading(`${lastname.toUpperCase()}<<${name.toUpperCase()}<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<`).slice(0, 30)}</p>
          </div>
        </div>
      </div>

      <div id="dots">
        <div className={`dot ${dotFront}`}></div>
        <div className={`dot ${dotBack}`}></div>
      </div>
    </div>
  );
};

export default DniComponent;
