import React from 'react'
import '../scss/Preloader.scss';

const Preloader = () => {
  return (
    <div id="preloader">
      <img src={require('../img/telefonos/telefono.webp')} alt="" />
      <img src={require('../img/credenciales.webp')} alt="" />
      <img src={require('../img/servicios.webp')} alt="" />

      {/* Previous LogIn */}
      <img src={require('../img/previous-log-in/giant-logo.webp')} alt="" />

      {/* LogIn */}
      <img src={require('../img/log-in/atras.webp')} alt="" />
      <img src={require('../img/log-in/mi-argentina-big-logo.webp')} alt="" />

      {/* Home */}
      <img src={require('../img/hamburger-menu-logo.webp')} alt="" />
      <img src={require('../img/header-logo.webp')} alt="" />
      <img src={require('../img/header-user-logo.webp')} alt="" />
      <img src={require('../img/turnos.webp')} alt="" />
      <img src={require('../img/servicios/documentos.webp')} alt="" />
      <img src={require('../img/servicios/vehiculos.webp')} alt="" />
      <img src={require('../img/servicios/salud.webp')} alt="" />
      <img src={require('../img/servicios/trabajo.webp')} alt="" />
      <img src={require('../img/servicios/turnos.webp')} alt="" />
      <img src={require('../img/servicios/cobros.webp')} alt="" />
      <img src={require('../img/servicios/tramites.webp')} alt="" />
      <img src={require('../img/servicios/hijos.webp')} alt="" />

      {/* Footer */}
      <img src={require('../img/footer/inicio.webp')} alt="" />
      <img src={require('../img/footer/inicio-selected.webp')} alt="" />
      <img src={require('../img/footer/novedades.webp')} alt="" />
      <img src={require('../img/footer/novedades-selected.webp')} alt="" />
      <img src={require('../img/footer/telefonos.webp')} alt="" />
      <img src={require('../img/footer/telefonos-selected.webp')} alt="" />
      <img src={require('../img/footer/tina.webp')} alt="" />

      {/* Options */}
      <img src={require('../img/options/atras.webp')} alt="" />
      <img src={require('../img/options/verified-user.webp')} alt="" />
      <img src={require('../img/options/mi-perfil.webp')} alt="" />
      <img src={require('../img/options/hijos-asociados.webp')} alt="" />
      <img src={require('../img/options/suscribir-servicios.webp')} alt="" />
      <img src={require('../img/options/seguridad-y-privacidad.webp')} alt="" />
      <img src={require('../img/options/acerca-de.webp')} alt="" />
      <img src={require('../img/options/terminos-y-condiciones.webp')} alt="" />
      <img src={require('../img/options/cerrar-sesion.webp')} alt="" />
      <img src={require('../img/options/volver-al-inicio.webp')} alt="" />

      {/* Mis documentos */}
      <img src={require('../img/mis-documentos/atras.webp')} alt="" />
      <img src={require('../img/mis-documentos/documento.webp')} alt="" />
      <img src={require('../img/mis-documentos/arrow-up.webp')} alt="" />
      <img src={require('../img/mis-documentos/arrow-down.webp')} alt="" />
      <img src={require('../img/mis-documentos/cambiar-dni.webp')} alt="" />

      {/* Dni digital */}
      <img src={require('../img/dni-digital/atras.webp')} alt="" />
      <img src={require('../img/dni-digital/detail.webp')} alt="" />
      <img src={require('../img/dni-digital/desactivate.webp')} alt="" />
      <img src={require('../img/dni-digital/qr.webp')} alt="" />
      <img src={require('../img/dni-digital/arrow-down.webp')} alt="" />

      {/* Mi Salud */}
      <img src={require('../img/mi-salud/organ-donation.webp')} alt="" />
      <img src={require('../img/mi-salud/vacunas.webp')} alt="" />

      {/* Mi Trabajo */}
      <img src={require('../img/mi-trabajo/atras.webp')} alt="" />
      <img src={require('../img/mi-trabajo/trabajo.webp')} alt="" />

      {/* Mis Turnos */}
      <img src={require('../img/mis-turnos/turnos-big-logo.webp')} alt="" />

      {/* Mis Trámites */}
      <img src={require('../img/mis-tramites/acceso.webp')} alt="" />
      <img src={require('../img/mis-tramites/atras.webp')} alt="" />
      <img src={require('../img/mis-tramites/luz-y-gas.webp')} alt="" />
      <img src={require('../img/mis-tramites/peajes.webp')} alt="" />
      <img src={require('../img/mis-tramites/tramites.webp')} alt="" />

      {/* Novedades */}
      <img src={require('../img/novedades/novedades-big-logo.webp')} alt="" />

      {/* Mi Perfil */}
      <img src={require('../img/mi-perfil/atras.webp')} alt="" />
      <img src={require('../img/mi-perfil/big-logo.webp')} alt="" />
      <img src={require('../img/mi-perfil/verified.webp')} alt="" />
    </div>
  )
}

export default Preloader