import React, { useEffect, useState, useCallback } from 'react';
import '../scss/ViewDetails.scss'
import PDF417Barcode from '../components/PDF417Barcode';
import Footer from '../components/Footer';
import Base64Image from '../components/Base64Image';

const ViewDetails = ({goToPage, footerClass, footerHome, footerNovedades, footerTelefono}) => {
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [sex, setSex] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [home, setHome] = useState('');
  const [dni, setDni] = useState('');
  const [base64String, setBase64String] = useState('');

  //datos opcionales
  const [nationality, setNationality] = useState('Argentina');
  const [examplary, setExamplary] = useState('A');
  const [emissionDate, setEmissionDate] = useState('0064929313892648');
  const [expirationDate, setExpirationDate] = useState({ day: '13', month: '02', year: '21' });
  const [tramitNumber, setTramitNumber] = useState({ day: "13", month: "02", year: "36" });
  const [tramitNumber11, setTramitNumber11] = useState();

  const initializeDataFromLocalStorage = useCallback(() => {
    setName(localStorage.getItem('name') || '');
    setLastname(localStorage.getItem('lastname') || '');
    setSex(localStorage.getItem('sex') || '');
    setHome(localStorage.getItem('home') || '');
    setDni(localStorage.getItem('dni') || '');
    const dateOfBirthString = localStorage.getItem('dateOfBirth') || '';
    if (dateOfBirthString) {
      setDateOfBirth(JSON.parse(dateOfBirthString));
    }
    setBase64String(localStorage.getItem('profilePhoto') || '');

    //datos opcionales
    setNationality(localStorage.getItem('nationality') || 'Argentina');
    setExamplary(localStorage.getItem('examplary') || 'A');
    setTramitNumber(localStorage.getItem('tramitNumber') || '0064929313892648');
    const emissionDateString = localStorage.getItem('emissionDate') || '{"day":"13","month":"02","year":"21"}';
    const expirationDateString = localStorage.getItem('expirationDate') || '{"day":"13","month":"02","year":"36"}';
    setEmissionDate(JSON.parse(emissionDateString));
    setExpirationDate(JSON.parse(expirationDateString));
    setTramitNumber11(String(tramitNumber).slice(0, 11));
  }, [setName, setLastname, setSex, setHome, setDni, setDateOfBirth, setNationality, setExamplary, setTramitNumber, setEmissionDate, setExpirationDate, setTramitNumber11, tramitNumber]);

  useEffect(() => {
    initializeDataFromLocalStorage();
  }, [initializeDataFromLocalStorage]);

  return (
    <div id="app" className='app-view-details'>
      <div id="dniHeader">
        <img src={require('../img/mis-documentos/atras.webp')} alt="" id="atras" onClick={goToPage} />
        <p>DNI Digital</p>
      </div>

      <div id="detailsViewerContainer">
        <div id="detailsViewer">

          <div id="data0">
            {base64String !== '' ?
              (<Base64Image base64={base64String} />)
              : null}
            <div id="barCode">
              <PDF417Barcode value={`${tramitNumber11}@${lastname.toUpperCase()}@${name.toUpperCase()}@${sex}@${dni}@${examplary}@${dateOfBirth.day}/${dateOfBirth.month}/20${dateOfBirth.year}@${emissionDate.day}/${emissionDate.month}/20${emissionDate.year}`} />
            </div>
          </div>

          <div id="data1">
            <div className="data">
              <p className="dataTitle">DNI</p>
              <p className="dataContent">{dni}</p>
            </div>
            <div className="data">
              <p className="dataTitle">Número de trámite</p>
              <p className="dataContent">{tramitNumber11}</p>
            </div>
            <div className="data">
              <p className="dataTitle">Fecha de emisión</p>
              <p className="dataContent">{emissionDate.day}/{emissionDate.month}/{emissionDate.year}</p>
            </div>
            <div className="data">
              <p className="dataTitle">Fecha de vencimiento</p>
              <p className="dataContent">{expirationDate.day}/{expirationDate.month}/{expirationDate.year}</p>
            </div>
          </div>

          <div id="divider-line"></div>

          <div id="data2">
            <div className="data">
              <p className="dataTitle">Nombre</p>
              <p className="dataContent">{name.toUpperCase()}</p>
            </div>
            <div className="data">
              <p className="dataTitle">Apellido</p>
              <p className="dataContent">{lastname.toUpperCase()}</p>
            </div>
            <div className="data">
              <p className="dataTitle">Sexo</p>
              <p className="dataContent">{sex.toUpperCase()}</p>
            </div>
            <div className="data">
              <p className="dataTitle">Fecha de nacimiento</p>
              <p className="dataContent">{dateOfBirth.day}/{dateOfBirth.month}/{Number(dateOfBirth.year) <= 40 ? '20' : '19'}{dateOfBirth.year}</p>
            </div>
            <div className="data">
              <p className="dataTitle">Nacionalidad</p>
              <p className="dataContent">{nationality.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}</p>
            </div>
            <div className="data">
              <p className="dataTitle">Dirección</p>
              <p className="dataContent">{home.toUpperCase()}</p>
            </div>
          </div>
        </div>
      </div>

      <Footer
        footerClass={footerClass}
        footerHome={footerHome}
        footerNovedades={footerNovedades}
        footerTelefono={footerTelefono} />
    </div>
  )
}

export default ViewDetails